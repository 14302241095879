/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
    bool, node,  string,
} from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getFeatureFlag, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrowser } from '../../../../../../state/ducks/App/ducks/Config/Config-Helpers';

const useStyles = makeStyles((theme) => ({
    container: (isType) => ({
        overflow: isType ? '' : 'hidden auto',
        '&::-webkit-scrollbar-thumb': {
            background: '#808080', // scroll bar color
            borderRadius: 10,
        },
        '&::-webkit-scrollbar': {
            background: 'transparent',
            width: 8,
        },
        scrollbarColor: '#808080 transparent', // for firefox
        scrollbarWidth: 'thin', // for firefox
        width: isType ? '100%' : 'auto',
        '@media screen and (min-width: 699px) and (max-width:1366px)': {
            maxWidth: isType ? '40%' : 'auto',
            marginRight: isType ? '20px' : '0px',
        },
    }),
    containerIE: (isType) => ({
        '@media screen and (min-width: 768px) and (max-width:1366px)': {
            maxWidth: isType ? '50%' : 'auto',
        },
    }),
    pdpImageStickyContainer: {
        width: '100%',
        position: 'sticky',
        top: 0,
        '&$facetsSticky': {
            height: 'max-content',
            top: 0,
            maxHeight: 'fit-content',
            overflow: 'inherit',
        },
        '@media screen and (min-width: 699px) and (max-width:1366px)': {
            maxWidth: '40%',
            marginRight: '20px',
        },
        '& $contentLeft': {
            maxWidth: '100%',
        },
    },
    facetsSticky: {
        position: 'sticky',
        top: 20,
        '& $filterHeader': {
            background: theme.palette.cms?.white || '#fff',
            zIndex: '9999',
            width: '215px',
            position: 'sticky',
        },
        '& $rootFacetsValueOverflow': {
            overflowY: 'inherit',
            maxHeight: 'inherit',
        },
        maxHeight: '100vh',
        overflow: 'auto',
    },
    facetsFixed: {
        '& $rootFacetsValueOverflow': {
            overflowY: 'inherit',
            maxHeight: 'inherit',
        },
    },
}));

const ScrollbarContainer = ({
    children, isFoodBrand, contentContainerId, type, pdpImageStickyEnabled, presentationFamily, style,
}) => {
    const pageType = type === 'productBase';
    const classes = useStyles(pageType);
    const [scroll, setScroll] = useState(1);
    const location = useLocation();
    const pdpImageSticky = pageType && pdpImageStickyEnabled;
    const scrollbarContainerSize = getBrowser() === 'Microsoft Internet Explorer' && presentationFamily === 'flower' && `${classes.containerIE}`;
    const onScroll = () => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined' && document.querySelector(`#${contentContainerId}`)) {
            const {
                top,
            } = document.querySelector(`#${contentContainerId}`).getBoundingClientRect();
            setScroll(top);
        }
    };

    useEffect(() => {
        if ((typeof document !== 'undefined' && isFoodBrand && type === 'collection') || pdpImageSticky) {
            document.addEventListener('scroll', onScroll);
            if (document.querySelector('#root')) {
                document.querySelector('#root').setAttribute('style', 'overflow: unset');
            }
        }

        return () => {
            if ((typeof document !== 'undefined' && isFoodBrand && type === 'collection') || pdpImageSticky) {
                document.removeEventListener('scroll', onScroll);
                if (document.querySelector('#root')) {
                    document.querySelector('#root').setAttribute('style', 'overflow: hidden');
                }
            }
        };
    }, [location?.pathname]);

    const loadFilterCss = () => {
        let facetCss = classes.root;
        if (isFoodBrand) {
            if (scroll <= 0) {
                facetCss = style ? `${classes.facetsSticky} ${style}` :  classes.facetsSticky;
            } else {
                facetCss = classes.facetsFixed;
            }
        }
        return facetCss;
    };

    return (
        <div className={`${classes.container} stickyImageContainer ${scrollbarContainerSize} ${loadFilterCss()} ${pdpImageSticky ? classes.pdpImageStickyContainer : ''}`}>
            {children}
        </div>
    );
};
ScrollbarContainer.defaultProps = {
    children: null,
    isFoodBrand: false,
    contentContainerId: '',
    type: '',
    pdpImageStickyEnabled: false,
    style: '',
};

ScrollbarContainer.propTypes = {
    children: node,
    isFoodBrand: bool,
    contentContainerId: string,
    type: string,
    pdpImageStickyEnabled: bool,
    presentationFamily: string.isRequired,
    style: string,
};
const mapStateToProps = (state) => ({
    pdpImageStickyEnabled: getFeatureFlag('pdp-image-sticky')(state),
    presentationFamily: getPresentationFamily(state),
});
const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(ScrollbarContainer);
