/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, array, func,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import scrollToTop from './scrollToTop';

const styles = (theme) => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0,
        boxShadow: 'none',
        '& li': {
            margin: '5px 5px',
        },
    },
    chip: {
        margin: theme.spacing(0.5),
    },
});

const SelectedDeselectedFilter = ({
    classes, checkedOptions, setCheckedOptions,
}) => {
    const seed = useUIDSeed();
    const selectDeselectArr = checkedOptions?.map(((b) => ({ value: b.substring(b.lastIndexOf('|') + 1, b.length), key: b })));
    const handleDelete = (chipToDelete) => () => {
        let checkedArr = [];
        if (checkedOptions && chipToDelete) {
            checkedArr = [...checkedOptions];
            if (checkedArr.some((check) => check === chipToDelete)) {
                checkedArr = checkedArr.filter((check) => check !== chipToDelete);
            } else {
                checkedArr.push(chipToDelete);
            }
            setCheckedOptions(checkedArr);
            scrollToTop();
        }
    };

    // update the price range bubbles format, removed decimals and add $ before price range
    const bubbleFormats = (data) => {
        const chiplabel = data.key === 'is_personalizable|true' ? 'Personalized' : data.value.replace(/_/g, ' ');
        // Remove underscore from string
        let label = data.value === 'onsale' ? 'on sale' : chiplabel;
        // Capitalize first letter of each word
        label = label.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
        let priceValue = label;
        const identifyCharOnly = (/[a-zA-Z]/).test(label);
        if (parseFloat(label).toFixed(1) && !identifyCharOnly) {
            const splitPriceRange = label.split('-');
            const firstPrice = Math.floor(splitPriceRange?.[0]);
            const lastPrice = Math.floor(splitPriceRange?.[1]);
            const fetchFirstPrice = Number.isNaN(firstPrice) ? '' : `$${firstPrice}`;
            const fetchLastPrice = Number.isNaN(lastPrice) ? '' : `- $${lastPrice}`;
            const updatedPrice = `${fetchFirstPrice} ${fetchLastPrice}`;
            priceValue = updatedPrice;
        }
        return priceValue;
    };

    return (
        selectDeselectArr.length > 0
        && (
            <Paper component="ul" className={classes.chipRoot} arial-label="hidden" tabIndex="-1">
                {selectDeselectArr?.map((data) => (
                    <li key={seed(data)}>
                        <Chip
                            label={bubbleFormats(data)}
                            onDelete={handleDelete(data.key)}
                            className={classes.chip}
                        />
                    </li>
                ))}
            </Paper>
        )

    );
};
SelectedDeselectedFilter.defaultProps = {
    checkedOptions: [],
};

SelectedDeselectedFilter.propTypes = {
    classes: object.isRequired,
    checkedOptions: array,
    setCheckedOptions: func.isRequired,
};

const enhance = compose(
    withRouter,
    withStyles(styles),
);

export default enhance(SelectedDeselectedFilter);
