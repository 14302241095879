/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    func, arrayOf, object, shape, string, bool,
} from 'prop-types';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { useUIDSeed } from 'react-uid';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation, withRouter } from 'react-router-dom';

import { useSelector } from 'react-redux';
import ProductFacetsView from './ProductFacetsView';
import ScrollbarContainer from '../ScrollbarContainer/ScrollbarContainer';
import SelectedDeselectedFilter from './SelectedDeselectedFilter';
import useCheckedFacets from '../../../../../helpers/hooks/useCheckedFacets';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import scrollToTop from './scrollToTop';

const styles = (theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background?.paper || theme.palette.primary?.contrastText,
    },
    nested: {
        padding: '0px 0px 0px 13px',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.contrastText,
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 0 10px 5px',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.95em',
            marginLeft: '5px',
        },
    },
    rootListItem: {
        width: '230px',
    },
    personalizedRootListItem: {
        display: 'flex',
        width: '250px',
        '& $rootFacetsValues': {
            borderBottom: 'none',
            paddingRight: '0px',
        },
    },
    facetsName: {
        fontWeight: '700',
        fontStyle: 'normal',
        fontSize: theme.typography?.fontSize || '16px',
        color: theme.palette.submit,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    facetsValuesContainer: {
        paddingLeft: '4px',
    },
    facetsValues: {
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '0.9em',
        height: '30px',
        textAlign: 'left',
        lineHeight: 'normal',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    checkbox: {
        padding: '0px 11px 0px 9px',
    },
    checked: {
        padding: '0px 11px 0px 9px',
        '&$checked': {
            color: theme?.palette?.cms?.checkbox,
        },
    },
    ListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 0px 10px 15px',
        width: '230px',
        height: '43px',
        outline: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.contrastText,
        },
    },
    rootFacetsValues: {
        backgroundColor: 'inherit',
        paddingRight: '20px',
        paddingBottom: '10px',
        borderBottom: `1px solid ${theme.palette.grey8}`,
    },
    rootFacetsValueOverflow: {
        overflowY: 'auto',
        paddingRight: '20px',
        borderBottom: '1px solid rgb(239, 239, 239)',
    },
    clearFilters: {
        display: 'contents',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    clearFiltersCopy: {
        fontSize: theme.palette?.text?.fontSize15 || '15px',
        paddingRight: '17px',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    filterBy: {
        paddingLeft: '15px',
        fontSize: theme.palette?.text?.fontSize20 || '20px',
    },
    floralFilterHeader: {
        paddingBottom: '10px',
    },
    facetNumber: {
        fontSize: theme.palette?.text?.fontSize14 || '14px',
        paddingLeft: 2,
    },
    listMore: {
        cursor: 'pointer',
        display: 'flex',
        padding: '5px 0px 5px 13px',
        alignItems: 'center',
        '& svg': {
            fontSize: theme.palette?.text?.fontSize15 || '15px',
            margin: '0 5px 0 7px',
        },
        '& span': {
            fontSize: theme.palette?.text?.fontSize17 || '17px',
        },
    },
    filterHeader: {
        paddingBottom: '5px',
        borderBottom: `1px solid ${theme.palette.grey8}`,
        width: '215px',
        marginBottom: '10px',
        '& $filterBy': {
            fontWeight: 700,
        },
    },
    facetsCheckbox: {
        padding: '0',
        '&.MuiCheckbox-colorPrimary.Mui-checked': {
            transform: 'scale(1.1)',
            width: 22,
            height: 22,
            color: theme.palette.cms?.filterCheckBoxColor || '#0774fc',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    filterChecked: {
        borderRadius: 3,
        width: 22,
        height: 22,
        boxShadow: 'inset 0 0 0 1px rgba(118,118,118,1), inset 0 -1px 0 rgba(118,118,118,1)',
        'input:hover ~ &': {
            backgroundColor: theme.palette.cms?.white || '#ffffff',
        },
    },
});

const GraphqlProductFacets = ({
    productFacets,
    setFilterDataInCategoryBuilder,
    classes,
    history,
    isFoodBrand,
    isFacetFiltersCountEnabled,
    track,
    filterOn,
    category,
}) => {
    const seed = useUIDSeed();
    const location = useLocation();
    const searchValue = unescape(location?.search);
    const headerName = 'Filter';
    const checkedFacetsData = useCheckedFacets();
    const [checkedOptions, setCheckedOptions] = useState(checkedFacetsData);
    const [openedFacets, setOpenedFacets] = useState([]);
    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));
    const handleOpenedFacets = (name) => {
        if (filterOn === 'Search') {
            openedFacets.push(name);
            setOpenedFacets(openedFacets);
        }
    };
    useEffect(() => {
        setFilterDataInCategoryBuilder(checkedFacetsData, checkedFacetsData?.length > 0);
        setCheckedOptions(checkedFacetsData);
    }, [searchValue]);
    const timerId = useRef(null);
    useEffect(() => {
        let delimiter = '&';
        if (isAggregatorFacetErrorResolver) {
            delimiter = '&facet=';
        }
        const urlSelectedParams = checkedOptions.join(delimiter);
        clearTimeout(timerId.current);
        timerId.current = setTimeout(() => { // delay the history for two ms
            let searchParam = '';
            if (urlSelectedParams) {
                searchParam = `facet=${urlSelectedParams}`;
            } else if (searchValue && !searchValue.includes('facet') && filterOn.toLowerCase() !== 'search') {
                searchParam = searchValue;
            }
            history.replace({
                search: searchParam,
                state: checkedOptions,
            });
        }, 200);
    }, [checkedOptions?.length]);
    const resetChecked = () => {
        setCheckedOptions([]);
        setTimeout(() => {
            history.replace({
                search: '',
                state: [],
            });
        }, 200);
        setFilterDataInCategoryBuilder([]);
        scrollToTop();
    };
    return (
        <ScrollbarContainer
            contentContainerId="productContainer"
            isFoodBrand={isFoodBrand}
            type="collection"
        >
            {!isFoodBrand
            && (
                <SelectedDeselectedFilter
                    checkedFacetsData={checkedFacetsData}
                    checkedOptions={checkedOptions}
                    setCheckedOptions={setCheckedOptions}
                    setFilterDataInCategoryBuilder={setFilterDataInCategoryBuilder}
                    filterOn={filterOn}
                />
            )            }
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                id="nested-list-subheader"
                subheader={(
                    <Grid container item sm={12} lg={12} className={isFoodBrand ? classes.filterHeader : classes.floralFilterHeader}>
                        <Grid item sm={5} lg={5} className={classes.filterBy}>
                            {headerName}
                        </Grid>
                        {!isFoodBrand && checkedOptions?.length > 0 && (
                            <Grid item sm={7} lg={7} className={classes.clearFilters} data-testid="facets-clear-filters" onClick={resetChecked}>
                                <span className={classes.clearFiltersCopy}> Clear Filters </span>
                                <Grid
                                    className={classes.clearFilterText}
                                >
                                    <CloseIcon fontSize="small" />
                                </Grid>
                            </Grid>
                        )}

                    </Grid>
                )}
                className={classes.root}
            >
                {productFacets?.map((facets) => {
                    let displayOpen =  facets?.displayOpen;
                    if (facets?.entries.length && checkedOptions.length) {
                        checkedOptions.forEach((facet) => facets.entries.forEach((val) => {
                            if (val.value === facet) {
                                displayOpen = true;
                            }
                        }));
                    } else if (filterOn === 'Search') {
                        displayOpen = openedFacets.includes(facets?.name) || facets?.displayOpen;
                    }
                    return (
                        <div key={seed(facets)}>
                            <ProductFacetsView
                                classes={classes}
                                facetsView={facets}
                                category={category}
                                setFilterDataInCategoryBuilder={setFilterDataInCategoryBuilder}
                                checkedOptions={checkedOptions}
                                setCheckedOptions={setCheckedOptions}
                                isFoodBrand={isFoodBrand}
                                onScroll={() => {}}
                                isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                                track={track}
                                filterOn={filterOn}
                                displayOpen={displayOpen}
                                setOpenedFacets={handleOpenedFacets}
                            />
                        </div>
                    );
                })}
            </List>
        </ScrollbarContainer>
    );
};

GraphqlProductFacets.defaultProps = {
    track: () => {},
    filterOn: '',
};

GraphqlProductFacets.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    productFacets: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    setFilterDataInCategoryBuilder: func.isRequired,
    isFoodBrand: bool.isRequired,
    isFacetFiltersCountEnabled: bool.isRequired,
    track: func,
    filterOn: string,
    category: object.isRequired,
};

export default withRouter(withStyles(styles)(GraphqlProductFacets));
